import axios from 'axios';

const API_URL = 'https://felusan.com/productos.php'; // URL de la API

//url api local http://localhost/client/productos.php
//url api hosteada 

// Función para obtener los productos
export const obtenerProductos = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data; // Retorna los datos de los productos
  } catch (error) {
    console.error('Error al obtener los productos:', error);
    throw error; // Lanza el error para manejarlo en otro lugar si es necesario
  }
};

