import React, { useState, useEffect } from 'react';
import { obtenerProductos } from '../api/productoApi';
import './Productos.css';

const Productos = () => {
  const [productos, setProductos] = useState([]);
  const [filtros, setFiltros] = useState({
    talle: [],
    genero: '',
    precio: { min: 0, max: Infinity },
  });
  const [filtroActivo, setFiltroActivo] = useState(null); // Para manejar el desplegable activo
  const [error, setError] = useState(null);

  useEffect(() => {
    const cargarProductos = async () => {
      try {
        const productosData = await obtenerProductos();
        setProductos(productosData);
      } catch (err) {
        setError('Hubo un problema al cargar los productos.');
      }
    };
    cargarProductos();
  }, []);

  

  const manejarCambioFiltro = (filtro, valor) => {
    setFiltros((prevFiltros) => {
      if (filtro === 'talle') {
        const tallesActualizados = prevFiltros.talle.includes(valor)
          ? prevFiltros.talle.filter((t) => t !== valor)
          : [...prevFiltros.talle, valor];
        return { ...prevFiltros, talle: tallesActualizados };
      } else if (filtro === 'genero') {
        // Si el género seleccionado ya está activo, lo limpiamos
        return { ...prevFiltros, genero: prevFiltros.genero === valor ? '' : valor };
      } else if (filtro === 'precio') {
        // Si el valor de precio es inválido, restauramos el filtro por defecto
        const nuevoPrecio = { ...prevFiltros.precio, ...valor };
        if (!nuevoPrecio.min && !nuevoPrecio.max) {
          return { ...prevFiltros, precio: { min: 0, max: Infinity } };
        }
        return { ...prevFiltros, precio: nuevoPrecio };
      }
      return prevFiltros;
    });
  };

  const alternarFiltroActivo = (filtro) => {
    setFiltroActivo((prev) => (prev === filtro ? null : filtro));
  };

  const filtrarProductos = () => {
    return productos.filter((producto) => {
      const cumpleTalle =
        filtros.talle.length === 0 || filtros.talle.some((talle) => producto.talles.includes(talle));
      const cumpleGenero = !filtros.genero || 
      producto.genero === filtros.genero || 
      producto.genero === 'X';
      const cumplePrecio =
        producto.precio >= filtros.precio.min && producto.precio <= filtros.precio.max;
      return cumpleTalle && cumpleGenero && cumplePrecio;
    });
  };

  const productosFiltrados = filtrarProductos();


  return (

<>

   
    <div className="container">
      {/* Filtros */}
      <div className="filtros">
        <div className={`filtro ${filtroActivo === 'talle' ? 'filtro-activo' : ''}`}>
          <button onClick={() => alternarFiltroActivo('talle')}>
            Talles <span>{filtroActivo === 'talle' ? '-' : '+'}</span>
          </button>
          <div className="filtro-opciones">
            <label>
              <input
                type="checkbox"
                onChange={() => manejarCambioFiltro('talle', 'S')}
              />
              S
            </label>
            <label>
              <input
                type="checkbox"
                onChange={() => manejarCambioFiltro('talle', 'M')}
              />
              M
            </label>
            <label>
              <input
                type="checkbox"
                onChange={() => manejarCambioFiltro('talle', 'L')}
              />
              L
            </label>
          </div>
        </div>

        <div className={`filtro ${filtroActivo === 'genero' ? 'filtro-activo' : ''}`}>
          <button onClick={() => alternarFiltroActivo('genero')}>
            Género <span>{filtroActivo === 'genero' ? '-' : '+'}</span>
          </button>
          <div className="filtro-opciones">
            <label>
              <input
                type="checkbox"
                checked={filtros.genero === 'M' }
                onChange={() => manejarCambioFiltro('genero', 'M')}
              />
              Hombre
            </label>
            <label>
              <input
                type="checkbox"
                checked={filtros.genero === 'F'}
                onChange={() => manejarCambioFiltro('genero', 'F')}
              />
              Mujer
            </label>
            <label>
              <input
                type="checkbox"
                checked={filtros.genero === 'X'}
                onChange={() => manejarCambioFiltro('genero', 'X')}
              />
              Unisex
            </label>
          </div>
        </div>

        <div className={`filtro ${filtroActivo === 'precio' ? 'filtro-activo' : ''}`}>
          <button onClick={() => alternarFiltroActivo('precio')}>
            Precio <span>{filtroActivo === 'precio' ? '-' : '+'}</span>
          </button>
          <div className="filtro-opciones">
            <label>
              Desde:
              <input
                type="number"
                className="form__field"
                value={filtros.precio.min === 0 ? '' : filtros.precio.min}
                onChange={(e) =>
                  manejarCambioFiltro('precio', { min: Number(e.target.value) || 0 })
                }
              />
            </label>
            <label>
              Hasta:
              <input
                type="number"
                className="form__field"
                value={filtros.precio.max === Infinity ? '' : filtros.precio.max}
                onChange={(e) =>
                  manejarCambioFiltro('precio', { max: Number(e.target.value) || Infinity })
                }
              />
            </label>
          </div>
        </div>
      </div>

      {/* Galería de productos */}
     

      <div className="productos-grid">
  {productosFiltrados.length > 0 ? (
    productosFiltrados.map((producto) => (
      <div key={producto.id} className="productoCard">
        <img src={producto.foto} alt={producto.nombre} />
        <h3>{producto.nombre}</h3>
        <p>Precio: ${producto.precio}</p>
        
        <button className="boton">Comprar</button>
      </div>
    ))
  ) : (
    <p>No hay productos disponibles.</p>
  )}
</div>

    </div>
  </>
  );
};

export default Productos;
