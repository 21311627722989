import React, { useState } from 'react';
import './App.css';
import Productos from './components/Productos'; // Importa el componente Productos
import Navbar from './components/Navbar';


function App() {

  return (
    <div className="App">
      <Productos /> 
    </div>
  );
}

export default App;
